import React from 'react';
import moment from 'moment';
import {
    Grid,
    Paper,
    MenuItem,
    Select,
    SelectChangeEvent,
    useTheme,
} from '@mui/material';
import { LiteAssessmentOverviewDetails, AssessmentStatus } from '../../type';
import {
    Typography12px600,
    Typography12px400,
} from '../../../../styles/Global.styles';

const AssessmentDetailHeaderCard = (prop: {
    liteAssessmentDetail: LiteAssessmentOverviewDetails;
    updateStatus: (status: AssessmentStatus) => void;
    reRender?: boolean;
}) => {
    const theme = useTheme();
    const { liteAssessmentDetail } = prop;
    const [status, setStatus] = React.useState<string>(
        liteAssessmentDetail.status,
    );

    React.useEffect(() => {
        setStatus(liteAssessmentDetail.status);
    }, [liteAssessmentDetail, prop.reRender]);

    const draftStateMenus = [
        { value: AssessmentStatus.DRAFT, label: 'Draft' },
        { value: AssessmentStatus.IN_PROGRESS, label: 'Publish' },
        { value: AssessmentStatus.DELETE, label: 'Delete' },
    ];

    const progressStateMenus = [
        { value: AssessmentStatus.IN_PROGRESS, label: 'In Progress' },
        { value: AssessmentStatus.DRAFT, label: 'Un-publish' },
        { value: AssessmentStatus.RESULTS_REVIEW, label: 'Review' },
    ];

    const reviewStateMenus = [
        { value: AssessmentStatus.RESULTS_REVIEW, label: 'Results Review' },
        { value: AssessmentStatus.IN_PROGRESS, label: 'Re-Open' },
        { value: AssessmentStatus.CLOSED, label: 'Close' },
    ];

    const closedStateMenus = [
        { value: AssessmentStatus.CLOSED, label: 'Closed' },
        { value: AssessmentStatus.IN_PROGRESS, label: 'Re-Open' },
        { value: AssessmentStatus.RESULTS_REVIEW, label: 'Review' },
        { value: AssessmentStatus.DELETE, label: 'Delete' },
    ];

    return (
        <Paper
            elevation={2}
            sx={{
                marginBottom: '20px',
                padding: '10px',
                border: '1px solid #E7E9F9',
                boxShadow: '0px 10px 20px 0px rgba(194, 194, 194, 0.16)',
            }}
        >
            <Grid container spacing={2} padding={'20px'}>
                <Grid item xs={12} sm={3}>
                    <Typography12px600 sx={{ color: '#464E5F', paddingBottom: '2px' }}>
                        Title
                    </Typography12px600>
                    <Typography12px400>
                        {liteAssessmentDetail?.name}
                    </Typography12px400>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography12px600 sx={{ color: '#464E5F', paddingBottom: '2px' }}>
                        Type
                    </Typography12px600>
                    <Typography12px400>
                        {liteAssessmentDetail?.assessment_definition.name}
                    </Typography12px400>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography12px600 sx={{ color: '#464E5F', paddingBottom: '2px' }}>
                        Close Date
                    </Typography12px600>
                    <Typography12px400>
                        {liteAssessmentDetail?.end_date
                            ? moment(liteAssessmentDetail?.end_date).format('MMM DD, YYYY')
                            : 'None'}
                    </Typography12px400>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Select
                        displayEmpty
                        value={status}
                        size="small"
                        inputProps={{ 'aria-label': 'Without label' }}
                        onChange={(event: SelectChangeEvent) => {
                            setStatus(event.target.value);
                            prop.updateStatus(event.target.value as AssessmentStatus);
                        }}
                        sx={{
                            backgroundColor: theme.palette.primary.main,
                            color: '#fff',
                            minWidth: '125px',
                            '& .MuiSelect-iconOutlined': {
                                color: '#fff',
                            },
                            '&:hover': {
                                '&& fieldset': {
                                    border: '1px solid' + theme.palette.primary.main,
                                },
                            },
                        }}
                    >
                        {liteAssessmentDetail.status === AssessmentStatus.DRAFT &&
                            draftStateMenus.map((item, index) => (
                                <MenuItem
                                    key={index}
                                    sx={{
                                        display: index === 0 ? 'none' : 'block',
                                    }}
                                    value={item.value}
                                >
                                    {item.label}
                                </MenuItem>
                            ))}

                        {liteAssessmentDetail.status ===
                            AssessmentStatus.IN_PROGRESS &&
                            progressStateMenus.map((item, index) => (
                                <MenuItem
                                    key={index}
                                    sx={{
                                        display: index === 0 ? 'none' : 'block',
                                    }}
                                    value={item.value}
                                >
                                    {item.label}
                                </MenuItem>
                            ))}

                        {liteAssessmentDetail.status ===
                            AssessmentStatus.RESULTS_REVIEW &&
                            reviewStateMenus.map((item, index) => (
                                <MenuItem
                                    key={index}
                                    sx={{
                                        display: index === 0 ? 'none' : 'block',
                                    }}
                                    value={item.value}
                                >
                                    {item.label}
                                </MenuItem>
                            ))}

                        {liteAssessmentDetail.status === AssessmentStatus.CLOSED &&
                            closedStateMenus.map((item, index) => (
                                <MenuItem
                                    key={index}
                                    sx={{
                                        display: index === 0 ? 'none' : 'block',
                                    }}
                                    value={item.value}
                                >
                                    {item.label}
                                </MenuItem>
                            ))}
                    </Select>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default AssessmentDetailHeaderCard;
