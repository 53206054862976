import React, { useState, useEffect, useMemo } from 'react';
import { Box, Button, CircularProgress, useTheme } from '@mui/material';
import Tab from '@mui/material/Tab';
import { TabContext, TabPanel, TabList } from '@mui/lab';
import { useParams } from 'react-router';
import { useAppSelector } from '../../../../redux/store';
import BarChartIcon from '@mui/icons-material/BarChart';
import { SaveButton } from '../../../Accounts/CreateClient/CreateClient.styles';
import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import useGetAssessmentNotes from '../../hooks/useGetAssessmentNotes';
import useGetScoresByCategory from '../../hooks/useGetScoresByCategory';
import { clearAssessmentNotes } from '../../reducers/getAssessmentNotes';
import { AssessmentNotesPayload, CategoryScoreDetails, ScoresByCategoryAssessmentDetails, AssessmentNote, CategoryAnalysisNotePayload } from '../../type'
import { CustomAccountBranding } from '../../../Branding/type';
import CategoryScoreDetailsTab from './CategoryScoreDetailsTab'
import AssessmentAnalysisNotes from './AssessmentAnalysisNotes';
import useAssessmentNotes from '../../hooks/usePutAssessmentNotes';
import usePutCategoryAnalysisNote from '../../hooks/usePutCategoryAnalysisNote';

interface CategoryAnalysisContainerProps {
    branding: CustomAccountBranding;
    id: string;
}

const CategoryAnalysisContainer: React.FC<CategoryAnalysisContainerProps> = ({ branding, id }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [value, setValue] = useState<number>(0);

    const getAssessmentNotesReducer = useGetAssessmentNotes();
    const getScoresByCategoryReducer = useGetScoresByCategory();
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        // setCurrentIndex(0);
    };

    const assessmentNote = useAppSelector((state) => {
        return state.assessmentReducers.getAssessmentNotesReducer?.assessment_notes as AssessmentNote;
    });

    const assessmentCategoryScoreDetails = useAppSelector((state) => {
        return state.assessmentReducers.getScoresByCategoryReducer?.assessment_category_details as ScoresByCategoryAssessmentDetails;
    });

    const [liveCategoryDetails, setLiveCategoryDetails] = React.useState<ScoresByCategoryAssessmentDetails | null>(null);
    const [liveAssessmentNotes, setLiveAssessmentNotes] = React.useState<AssessmentNote | null>(null);

    const handleAssessorScoreChange = (
        categoryId: number,
        questionId: number,
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const assessorScoreValue = parseFloat(event.target.value);
        setLiveCategoryDetails((prevDetails) => {
            if (!prevDetails) return null;

            // Update the specific assessor score for the category/question
            const updatedDetails = {
                ...prevDetails,
                scores_by_category: prevDetails.scores_by_category.map((category) =>
                    category.category_id === categoryId
                        ? {
                            ...category,
                            questions: category.questions.map((question) =>
                                question.question_id === questionId
                                    ? { ...question, assessor_score: assessorScoreValue }
                                    : question
                            ),
                        }
                        : category
                ),
            };
            return updatedDetails;
        });
    };

    useEffect(() => {
        dispatch(clearAssessmentNotes());
        getAssessmentNotesReducer.getNotes(id);
        getScoresByCategoryReducer.getAssessmentCategoryScoreDetails(id);
    }, [id]);

    useEffect(() => {
        if (assessmentCategoryScoreDetails) {
            setLiveCategoryDetails(assessmentCategoryScoreDetails);
        }
    }, [assessmentCategoryScoreDetails]);

    useEffect(() => {
        setLiveAssessmentNotes(assessmentNote);
    }, [assessmentNote]);

    const { updateAssessmentNotes } = useAssessmentNotes();
    const { updateCategoryAnalysisNote } = usePutCategoryAnalysisNote();

    const onNotesSubmit = (data: AssessmentNotesPayload) => {
        const defaultValues = {
            assessment_id: assessmentCategoryScoreDetails?.uuid
        };

        const convertPayloadToNote = (payload: AssessmentNotesPayload): AssessmentNote => {
            return {
                id: payload.id,
                text: payload.text || null,  // Convert to `null` if the string is empty
                assessment_id: parseInt(payload.assessment_id), // Convert assessment_id to a number
                created_by_id: '', // Placeholder value, add actual value if available
                created_at: null,  // Placeholder value, add actual value if available
                last_updated_by_id: '', // Placeholder value, add actual value if available
                last_updated_at: null, // Placeholder value, add actual value if available
                development_opportunities: payload.development_opportunities || null,
                implementation_details_and_timings: payload.implementation_details_and_timings || null,
                key_priorities: payload.key_priorities || null,
            };
        };

        setLiveAssessmentNotes(convertPayloadToNote(data));

        updateAssessmentNotes({
            ...defaultValues,
            ...data,
        });
    };

    const onCategoryNotesSubmit = (data: CategoryAnalysisNotePayload, assessment_uuid: string) => {
        data.strengths =
            data.strengths_string && data.strengths_string.length > 0
                ? data.strengths_string?.split('\n').filter((i) => i)
                : data.strengths_string == ''
                    ? []
                    : undefined;
        data.strengths_string = undefined;

        data.challenges_threats =
            data.challenges_threats_string &&
                data.challenges_threats_string.length > 0
                ? data.challenges_threats_string?.split('\n').filter((i) => i)
                : data.challenges_threats_string == ''
                    ? []
                    : undefined;
        data.challenges_threats_string = undefined;

        data.root_causes =
            data.root_causes_string && data.root_causes_string.length > 0
                ? data.root_causes_string?.split('\n').filter((i) => i)
                : data.root_causes_string == ''
                    ? []
                    : undefined;
        data.root_causes_string = undefined;

        data.possible_offerings =
            data.possible_offerings_string &&
                data.possible_offerings_string.length > 0
                ? data.possible_offerings_string?.split('\n').filter((i) => i)
                : data.possible_offerings_string == ''
                    ? []
                    : undefined;
        data.possible_offerings_string = undefined;

        updateCategoryAnalysisNote(data);
        // getScoresByCategoryReducer.getAssessmentCategoryScoreDetails(
        //     assessment_uuid,
        // );

        setLiveCategoryDetails((prevDetails) => {
            if (!prevDetails) return prevDetails; // No update if state is null

            const updatedScoresByCategory = prevDetails.scores_by_category.map((category) => {
                if (category.category_id === data.question_category_id) {
                    return {
                        ...category,
                        category_analysis_notes: {
                            ...category.category_analysis_notes,
                            strengths: data.strengths || category.category_analysis_notes.strengths,
                            challenges_threats: data.challenges_threats || category.category_analysis_notes.challenges_threats,
                            root_causes: data.root_causes || category.category_analysis_notes.root_causes,
                            possible_offerings: data.possible_offerings || category.category_analysis_notes.possible_offerings,
                            go_forward_plan: data.go_forward_plan || category.category_analysis_notes.go_forward_plan,
                        },
                    };
                }
                return category; // No match, return category as is
            });

            // Return the updated state object
            return {
                ...prevDetails,
                scores_by_category: updatedScoresByCategory,
            };
        });
    };

    return (
        <Box sx={{ fontFamily: 'Inter' }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginLeft: '20px',
                }}
            >
                <SaveButton
                    onClick={() => {
                        navigate(-1);
                    }}
                >
                    Back
                </SaveButton>
            </Box>
            {!liveCategoryDetails || value == null || !liveCategoryDetails.scores_by_category ? (
                <CircularProgress sx={{ margin: '20vh 48%' }} />
            ) : (
                <TabContext value={value.toString()}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1em' }}>
                        <TabList
                            sx={{
                                flexWrap: 'wrap', // Allow the tabs to wrap into multiple rows
                                '& .MuiTabs-flexContainer': {
                                    flexWrap: 'wrap', // Ensure the tab container wraps as well
                                },
                                '& .MuiTab-root': {
                                    textTransform: 'none',    // Disable the uppercase transformation
                                    // minWidth: '150px',        // Set a minimum width for each "button"
                                    fontSize: '0.75em',
                                    padding: '0em 0.5em',      // Padding to make them look like buttons
                                    margin: '0px 5px 0px 5px',            // Add spacing between the tabs
                                    borderRadius: '5px',   // Rounded corners for button look
                                    borderBottomLeftRadius: '0px',
                                    borderBottomRightRadius: '0px',
                                    border: '1px solid #f4f4f4', // Border to give a button-like appearance
                                    borderBottom: 'none',
                                    backgroundColor: '#ffffff', // Light background to mimic button
                                    color: '#333',            // Text color
                                    '&:hover': {
                                        backgroundColor: '#fbfbfb', // Slightly darker background on hover
                                    },
                                    '&.Mui-selected': {
                                        backgroundColor: branding.primary_color, // Button-like background for selected tab
                                        color: 'white',             // White text on selected tab
                                        border: `1px solid ${branding.primary_color}`,     // Border to match the selected background
                                    },
                                },
                            }}
                            onChange={handleChange}
                            TabIndicatorProps={{
                                style: { display: 'none' }, // Hide the default indicator
                            }}
                            aria-label="styled tabs as buttons"
                        >
                            {liveCategoryDetails.scores_by_category.map(
                                (category: CategoryScoreDetails, index) => (
                                    <Tab
                                        key={category.category_id || index}
                                        label={category.category_name}
                                        value={index.toString()}
                                    />
                                ))}
                            <Tab label="Notes" value="notes" />
                        </TabList>
                    </Box>

                    {liveCategoryDetails.scores_by_category.map(
                        (category: CategoryScoreDetails, index) => (
                            <TabPanel
                                sx={{ padding: '0' }}
                                value={index.toString()}
                                key={category.category_id || index}
                            >
                                <CategoryScoreDetailsTab
                                    branding={branding}
                                    categoryScoreDetail={category}
                                    handleAssessorScoreChange={handleAssessorScoreChange}
                                    resultChartType={liveCategoryDetails.result_chart_type}
                                    onCategoryNotesSubmit={onCategoryNotesSubmit}
                                />
                            </TabPanel>
                        ),
                    )}
                    <TabPanel value="notes"
                        sx={{ padding: '0' }}>
                        <AssessmentAnalysisNotes
                            initialAssessmentNotes={liveAssessmentNotes}
                            onNotesSubmit={onNotesSubmit}
                        />
                    </TabPanel>
                </TabContext>
            )}

            {/* <IndividualResponseModal
                open={modalOpen}
                handleClose={handleCloseModal}
                evaluationId={id || ''}
                questionCategoryId={value || ''}
                questionCategory={selectedCategory || ''}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
            /> */}
        </Box>
    );
};

export default CategoryAnalysisContainer;
