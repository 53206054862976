import React, { FunctionComponent } from 'react';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import WatchLaterIcon from '@mui/icons-material/WatchLater';

const OverviewBlob: FunctionComponent = () => (
  <Box
    sx={{
      marginTop: '3%',
    }}
  >
    <Typography variant="h4" color="primary">
      Florida Manufacturer Employer of Choice
    </Typography>
    <Typography variant="h6" color="primary" sx={{ marginTop: '5%' }}>
      What is an Employer of Choice?
    </Typography>
    <Typography variant="body1" sx={{ marginTop: '5%' }}>
      Attracting and retaining a skilled workforce is challenging. Employees are
      in search of quality jobs, and organizations that emphasize the importance
      of job quality stand out as &quot;employers of choice&quot;.
    </Typography>

    <Typography variant="body1" sx={{ marginTop: '3%' }}>
      According to a study by the U.S. Department of Commerce, there are many
      drivers of job quality that contribute to an individual&apos;s complete
      work experience. Compensation and benefits matter, but other aspects such
      as workplace safety and health, employee input, predictable scheduling,
      opportunities for skill development, and career progression are equally
      vital.
    </Typography>

    <Typography variant="body1" sx={{ marginTop: '3%' }}>
      This survey is designed to provide a comprehensive overview of an
      organization&apos;s employment practices, focusing on key factors that
      contribute to its attractiveness as an employer of choice. It gathers data on
      recruitment strategies, hiring approaches, employee retention, and
      promotion practices to identify opportunities for growth within the company.
    </Typography>

    <Typography variant="body1" sx={{ marginTop: '3%' }}>
      Additionally, the survey looks into the company&apos;s commitment to
      employee development through training and upskilling initiatives, the
      range and adequacy of employee benefits, and financial wellness programs,
      including retirement plans and bonus structures. It also assesses the
      supportiveness of the working environment, including onboarding
      effectiveness for both new hires and internally promoted employees,
      workplace safety, flexibility, and the overall culture of employee
      engagement and participation.
    </Typography>
    <Typography variant="body1" sx={{ marginTop: '3%' }}>
      Ultimately, this survey seeks to illuminate how these practices affect
      employee satisfaction, retention, and the organization&apos;s ability to
      attract top talent, providing a holistic view of its strengths and areas
      for improvement in fostering a positive and productive workplace.
    </Typography>
    <Typography variant="body1" sx={{ marginTop: '3%' }}>
      You can start the survey at your convenience and your responses will be automatically saved, 
      allowing you to return and complete the survey whenever it suits you.
    </Typography>
  </Box>
);

const IntakeFormOverView: FunctionComponent = () => {
  return (
    <Container>
      <OverviewBlob />
      <Grid
        container
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
        sx={{ marginTop: '3%' }}
      >
        <Grid item sx={{ display: 'flex' }}>
          <WatchLaterIcon sx={{ marginRight: '.2rem' }}></WatchLaterIcon>
          <Typography variant="body2" sx={{ marginY: 'auto' }}>
            Approximately 1 hour
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default IntakeFormOverView;
