import { Box, CircularProgress, Container, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import { TabContext, TabPanel, TabList } from '@mui/lab';
import { useNavigate } from 'react-router';
import React, { useEffect } from 'react';
import { RootStoreType } from '../../../../redux/store.type';
import { useAppSelector } from '../../../../redux/store';
import useGetAccount from '../../../Assessments/hooks/useGetAccount';
import usePutAssessmentStatus from '../../hooks/usePutAssessmentStatus';
import { AccountSettingsUser } from '../../../Accounts/AccountSettings/AccountSettings';
import ConfirmationBox from '../../../../components/ConfirmationBox/ConfirmBox';
import translation from '../../../../locales/en/translation.json';
import { SaveButton } from '../../../Accounts/CreateClient/CreateClient.styles';
import useAccountSettingsUsers from '../../../Accounts/hooks/useGetAccountSettingsUsers';
import { useDispatch } from 'react-redux';
import { resetState } from '../../../Accounts/reducers/getAccountSetting';
import AssessmentDetailsSummary from './Summary/AssessmentDetailsSummary';
import { CustomAccountBranding } from '../../../Branding/type';
import useGetLiteAssessmentDetails from '../../hooks/useGetLiteAssessmentDetails';
import AssessmentDetailHeaderCard from './AssessmentDetailHeaderCard';
import { clearLiteAssessmentDetails } from '../../reducers/getLiteAssessmentDetails';
import { clearScoresByCategory } from '../../reducers/getScoresByCategory';
import { AssessmentStatus, ScoresByCategoryAssessmentDetails, LiteAssessmentOverviewDetails } from '../../type';
import useDeleteAssessment from '../../hooks/useDeleteAssessment'
import AssessmentAnalysis from './Analysis/AssessmentAnalysis';
import useGetScoresByCategory from '../../hooks/useGetScoresByCategory';

interface AssessmentDetailsViewProps {
    branding: CustomAccountBranding;
    id: string;
}

const AssessmentDetailsView: React.FC<AssessmentDetailsViewProps> = ({ branding, id }) => {
    const [value, setValue] = React.useState('1');
    const [openConfirmBox, setOpenConfirmBox] = React.useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const getLiteAssessmentDetailsReducer = useGetLiteAssessmentDetails();
    const getScoresByCategoryReducer = useGetScoresByCategory();
    const { loading, editAssessmentStatus } = usePutAssessmentStatus();
    const { deleteAssessmentById } = useDeleteAssessment();

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const liteAssessmentDetail = useAppSelector((state) => {
        return state.assessmentReducers.getLiteAssessmentDetailsReducer?.lite_assessment_details as LiteAssessmentOverviewDetails;
    });

    const assessmentCategoryScoreDetails = useAppSelector((state) => {
        return state.assessmentReducers.getScoresByCategoryReducer?.assessment_category_details as ScoresByCategoryAssessmentDetails;
    });

    React.useEffect(() => {
        if (!loading) {
            dispatch(clearLiteAssessmentDetails());
            dispatch(clearScoresByCategory());
            getLiteAssessmentDetailsReducer.getLiteDetails(id || '');
            getScoresByCategoryReducer.getAssessmentCategoryScoreDetails(id);
        }
    }, [loading, id]);

    // return !liteAssessmentDetail ||
    //     !assessmentCategoryScoreDetails ||
    //     loading ||
    //     getLiteAssessmentDetailsReducer.loading ||
    //     getLiteAssessmentDetailsReducer.error ||
    //     getScoresByCategoryReducer.loading ||
    //     getScoresByCategoryReducer.error ? (
    //     <CircularProgress sx={{ margin: '20vh 48%' }} />
    // ) : (
    if (loading ||
        getLiteAssessmentDetailsReducer.loading) {
        return (
            <Container>
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                    <CircularProgress />
                </Box>
            </Container>
        );
    }

    return liteAssessmentDetail && (
        <>
            {openConfirmBox && (
                <ConfirmationBox
                    doOpen={openConfirmBox}
                    deleteRecord={() => {
                        if (assessmentCategoryScoreDetails.uuid) {
                            deleteAssessmentById(assessmentCategoryScoreDetails.uuid);
                        }
                    }}
                    doClose={() => {
                        setOpenConfirmBox(false);
                    }}
                    confirmationMessage={translation.evaluation_delete_confirm_text}
                />
            )}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginLeft: '20px',
                    paddingBottom: '20px',
                }}
            >
                <SaveButton
                    onClick={() => {
                        navigate(-1);
                    }}
                >
                    Back
                </SaveButton>
                {/* Only show the Edit button if there is not a child_assessment */}
                {!liteAssessmentDetail.child_assessment && (
                    <SaveButton
                        onClick={() => {
                            dispatch(resetState());
                            navigate(`/edit-evaluation-assessment/${id as string}`);
                        }}
                    >
                        Edit
                    </SaveButton>
                )}
            </Box>
            <Box sx={{ padding: '8px', marginBottom: '10px' }}>
                <Typography
                    sx={{
                        color: '#1C1C1C',
                        fontFeatureSettings: "'cv11' on, 'cv01' on, 'ss01' on",
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: '20px',
                    }}
                >
                    {branding?.evaluation_property_name_translation} Details
                </Typography>
            </Box>
            <AssessmentDetailHeaderCard
                reRender={openConfirmBox}
                liteAssessmentDetail={liteAssessmentDetail}
                updateStatus={(status: AssessmentStatus) => {
                    if (status !== AssessmentStatus.DELETE) {
                        if (liteAssessmentDetail) {
                            if (!loading) {
                                editAssessmentStatus(liteAssessmentDetail.uuid, status);
                            }
                        }
                    } else {
                        setOpenConfirmBox(true);
                    }
                }}
            />

            <TabContext value={value}>
                <Box>
                    <TabList
                        sx={{
                            '& .MuiTab-root': {
                                '&.Mui-selected': {
                                    color: '#00DB99',
                                },
                                textTransform: 'none',
                            },
                        }}
                        onChange={handleChange}
                        TabIndicatorProps={{ style: { backgroundColor: '#00DB99' } }}
                        aria-label="lab API tabs example"
                    >
                        <Tab label="Summary" value="1" />
                        <Tab label="Analysis" value="2" />
                    </TabList>
                </Box>
                <TabPanel sx={{ padding: '0' }} value="1">
                    <AssessmentDetailsSummary
                        accountInformation={liteAssessmentDetail.account}
                        liteAssessmentDetail={liteAssessmentDetail}
                        accountUserData={liteAssessmentDetail.account.account_executives}
                        accountExecutiveNameTranslation={branding.account_executive_name_translation}
                        //Specifically pass in ChildAssessment for allowing status change on Child Assessments
                        updateStatus={(status: AssessmentStatus) => {
                            if (status !== AssessmentStatus.DELETE) {
                                if (liteAssessmentDetail.child_assessment) {
                                    if (!loading) {
                                        editAssessmentStatus(liteAssessmentDetail.child_assessment?.uuid, status);
                                    }
                                }
                            } else {
                                setOpenConfirmBox(true);
                            }
                        }}
                    />
                </TabPanel>
                <TabPanel sx={{ padding: '0' }} value="2">
                    <AssessmentAnalysis assessmentCategoryScoreDetails={assessmentCategoryScoreDetails} detailsLoading={getScoresByCategoryReducer.loading}/>
                </TabPanel>
            </TabContext>
        </>
    );
};

export default AssessmentDetailsView;
