import { combineReducers } from '@reduxjs/toolkit';
import {
    AssessmentDetailsReducer,
    LiteAssessmentDetailsReducer,
    PublicAssessmentDetailsReducer,
    AssessmentDefinitionsReducer,
    AssessmentCreationReducer,
    ScoresByCategoryReducer,
    AssessmentAssessorScoreReducer,
    AssessmentNotesReducer
} from '../type';
import getAssessmentDetailsReducer from './getAssessmentDetails';
import getLiteAssessmentDetailsReducer from './getLiteAssessmentDetails';
import getAssessmentNotesReducer from './getAssessmentNotes';
import getPublicAssessmentDetailsReducer from './getPublicAssessmentDetails';
import getScoresByCategoryReducer from './getScoresByCategory'
import getAssessmentsReducer, { AssessmentsReducer } from './getAssessments';
import getAccountAssessmentsReducer, { AccountAssessmentsReducer } from './getAccountAssessments';
import getAccountAssessmentDefinitionsScoresReducer, { AccountAssessmentDefinitionsScoresReducer } from './getAccountAssessmentDefinitionsScores';
import getAssessmentScoreAnalysisReducer, { AssessmentScoreAnalysisReducer } from './getAssessmentScoreAnalysis';
import getCreatedAssessmentReducer from './postAssessment';
import accountInfoReducer, { AccountDetailInitialState } from '../../Assessments/reducers/getAccount';
import getAssessmentDefinitionsReducer from './getAssessmentDefinitions';
import postAssessmentAssessorScoreReducer from './postAssessmentAssessorScore';

export default combineReducers<{
    getAssessmentDetailsReducer: AssessmentDetailsReducer;
    getLiteAssessmentDetailsReducer: LiteAssessmentDetailsReducer;
    getAssessmentNotesReducer: AssessmentNotesReducer;
    getPublicAssessmentDetailsReducer: PublicAssessmentDetailsReducer;
    getScoresByCategoryReducer: ScoresByCategoryReducer;
    getAssessmentsReducer: AssessmentsReducer;
    getAccountAssessmentsReducer: AccountAssessmentsReducer;
    getAccountAssessmentDefinitionsScoresReducer: AccountAssessmentDefinitionsScoresReducer;
    getAssessmentScoreAnalysisReducer: AssessmentScoreAnalysisReducer;
    accountInfoReducer: AccountDetailInitialState;
    getAssessmentDefinitionsReducer: AssessmentDefinitionsReducer;
    getCreatedAssessmentReducer: AssessmentCreationReducer;
    postAssessmentAssessorScoreReducer: AssessmentAssessorScoreReducer;
}>({
    getAssessmentDetailsReducer,
    getLiteAssessmentDetailsReducer: getLiteAssessmentDetailsReducer,
    getAssessmentNotesReducer,
    getPublicAssessmentDetailsReducer,
    getScoresByCategoryReducer,
    getAssessmentsReducer,
    getAccountAssessmentsReducer,
    getAccountAssessmentDefinitionsScoresReducer,
    getAssessmentScoreAnalysisReducer,
    accountInfoReducer,
    getAssessmentDefinitionsReducer,
    getCreatedAssessmentReducer,
    postAssessmentAssessorScoreReducer
});
